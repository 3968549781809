import React from 'react';
import PropTypes from 'prop-types';
import { Container, Content } from '../../components/home/homeStyledComponents';
import MenuBar from '../../components/home/MenuBar';
import Footer from '../../components/home/v3/Footer';
import ResetPasswordBlock from '../../components/MyAccount/ResetPasswordBlock';
import callApi from '../../services/api';

class ResetPassword extends React.Component {
  constructor() {
    super();
    this.state = { status: 'loading' };
    this.sendResetPassword = this.sendResetPassword.bind(this);
  }

  componentDidMount() {
    const { token } = this.props;
    this.setState({ token }); // eslint-disable-line
    if (!token) {
      this.setState({ status: 'error' }); // eslint-disable-line
    } else {
      this.checkForgottenPasswordRequest(token);
    }
  }

  checkForgottenPasswordRequest(token) {
    callApi(`checkForgottenPasswordRequest/${token}`).then(() => {
      this.setState({ status: 'ready' });
    }).catch(() => this.setState({ status: 'error' }));
  }


  sendResetPassword() {
    const { token, password } = this.state;
    this.setState({ status: 'loading' });
    callApi('resetPassword', 'post', { token, password })
      .then(() => {
        this.setState({ status: 'success' });
      })
      .catch(() => {
        this.setState({ status: 'error' });
      });
  }

  render() {
    return (
      <Container>
        <Content>
          <MenuBar />
          <ResetPasswordBlock
            status={this.state.status}
            inputChange={(field, value) => this.setState({ [field]: value })}
            submit={this.sendResetPassword}
          />
          <Footer />
        </Content>
      </Container>
    );
  }
}

ResetPassword.propTypes = {
  token: PropTypes.string.isRequired,
};

export default ResetPassword;
